import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    resize: 'rs',
    size: 's',
    fit: 'rt',
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    enlarge: 'el',
    extend: 'ex',
    gravity: 'g',
    crop: 'c',
    padding: 'pd',
    trim: 't',
    rotate: 'rot',
    quality: 'q',
    maxBytes: 'mb',
    background: 'bg',
    backgroundAlpha: 'bga',
    blur: 'bl',
    sharpen: 'sh',
    watermark: 'wm',
    preset: 'pr',
    cacheBuster: 'cb',
    stripMetadata: 'sm',
    stripColorProfile: 'scp',
    autoRotate: 'ar',
    filename: 'fn',
    format: 'f',
  },
  formatter: (key: string, value: string | number) => `${key}:${value}`,
})

function urlSafeBase64(value: string) {
  return btoa(value).replace(/=+/g, '').replace(/[+/]/g, m => (m === '+' ? '-' : '_'))
}

function sign(target: string) {
  return urlSafeBase64(target)
}

const defaultModifiers = {
  fit: 'fill',
  width: 0,
  height: 0,
  gravity: 'no',
  enlarge: 1,
  format: 'webp',
}

export const getImage: ProviderGetImage = (src, options) => {
  const runtimeConfig = useRuntimeConfig()
  const { modifiers } = options
  const mergeModifiers = { ...defaultModifiers, ...modifiers } as unknown as Record<string, string>

  // console.debug({ options, runtimeConfig }) // for an unknown reason this does no work in cluster environment
  const imgproxyUrl = runtimeConfig.public.imgproxyUrl || options.imgproxyUrl || '/_'

  if (src.startsWith('/') || !imgproxyUrl)
    return { url: src }

  const encodedUrl = urlSafeBase64(src)
  const path = joinURL('/', operationsGenerator(mergeModifiers), encodedUrl)
  // const path = joinURL('/', operationsGenerator(mergeModifiers), encodeURIComponent(src))
  const signature = sign(path)

  return {
    url: joinURL(imgproxyUrl, signature, path),
  }
}
