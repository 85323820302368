import type * as stripeJs from '@stripe/stripe-js'
import { colors } from '@unocss/preset-mini/colors'
import { isClient } from '@vueuse/core'

export const isDarkMode = () => isClient && window.document.documentElement.classList.contains('dark-mode')

export default defineAppConfig({
  stripe: {
    elements: {
      options: {
        fonts: [
          {
            family: 'Averta',
            weight: '400',
            display: 'swap',
            src: 'url(/fonts/averta-regular.woff2)',
          },
          {
            family: 'Averta',
            weight: '600',
            display: 'swap',
            src: 'url(/fonts/averta-semi-bold.woff2)',
          },
          {
            family: 'Averta',
            weight: '700',
            display: 'swap',
            src: 'url(/fonts/averta-bold.woff2)',
          },
        ],
      },
    },

    card: {
      // hidePostalCode: true,
      classes: {
        base: '',
        complete: '',
        empty: '',
        focus: '',
        invalid: '',
        webkitAutofill: '',
      },
      style: {
        base: {
          'backgroundColor': 'transparent',
          'color': isDarkMode() ? colors.slate[700] : colors.slate[900],
          'fontFamily': 'Averta,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
          'fontSize': '16px',
          // 'fontSize': '32px',
          'fontSmoothing': 'antialiased',
          'lineHeight': '20px',
          //
          'iconColor': isDarkMode() ? colors.slate[400] : colors.slate[500],
          //
          ':hover': {},
          ':focus': {
            color: isDarkMode() ? colors.slate[700] : colors.slate[900],
          },
          '::placeholder': {
            color: isDarkMode() ? colors.slate[400] : colors.slate[500],
          },
          '::selection': {},
          ':-webkit-autofill': {},
          ':disabled': {
            color: colors.slate[500],
          },
        },
        complete: {},
        empty: {},
        invalid: {
          'iconColor': isDarkMode() ? colors.red[400] : colors.rose[400],
          'color': isDarkMode() ? colors.rose[200] : colors.red[900],
          '::placeholder': {
            color: isDarkMode() ? colors.red[400] : colors.rose[400],
          },
        },
      },
    } satisfies stripeJs.StripeCardElementOptions,

    p24Bank: {
      style: {
        base: {
          'color': isDarkMode() ? colors.slate[700] : colors.slate[900],
          'fontFamily': 'Averta,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
          'fontSize': '16px',
          'fontSmoothing': 'antialiased',
          'padding': '8px',
          ':hover': {
            color: isDarkMode() ? colors.slate[700] : colors.slate[900],
          },
          ':focus': {
            color: isDarkMode() ? colors.slate[700] : colors.slate[900],
          },
          '::placeholder': {
            color: isDarkMode() ? colors.slate[400] : colors.slate[500],
          },
          ':disabled': {
            color: colors.slate[500],
          },
        },
        complete: {
          color: isDarkMode() ? colors.slate[700] : colors.slate[900],
          iconColor: isDarkMode() ? colors.slate[400] : colors.slate[500],
        },
        invalid: {
          'iconColor': isDarkMode() ? colors.red[400] : colors.rose[400],
          'color': isDarkMode() ? colors.rose[200] : colors.red[900],
          '::placeholder': {
            color: isDarkMode() ? colors.red[400] : colors.rose[400],
          },
        },
      },
      classes: {
        base: '',
        complete: '',
        empty: '',
        focus: '',
        invalid: '',
      },
    } as stripeJs.StripeP24BankElementOptions,
  },
})
