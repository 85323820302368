import type { CollectionListItemFragment, CollectionListOptions, InputMaybe } from '#graphql-operations'
import { LIST_QUERY_LIMIT } from '~/constants'
import { buildTree } from '~/composables/collections'

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error)
    return {}

  const fetchCollections = (options?: InputMaybe<CollectionListOptions>) => useGraphqlQuery('collections', { options })

  const collections = useState<Record<string, CollectionListItemFragment> | null>('collections')
  const rootCollections = computed(() => Object.values(collections.value ?? {}).filter(collection => collection.parent?.name === '__root_collection__').sort((a, b) => a.position - b.position))
  const collectionList = computed(() => Object.values(collections.value ?? {}).sort((a, b) => a.position - b.position))
  const collectionTree = computed(() => buildTree(collectionList.value))

  await useAsyncData('topLevelCollections', () => fetchCollections({
    take: LIST_QUERY_LIMIT,
    topLevelOnly: true,
  }).then((result) => {
    const items = result.data.collections.items
    // convert to map of id -> collection
    const map = Object.fromEntries(items.map(item => [item.id, item]))
    collections.value = Object.assign({}, collections.value, map)
    return items
  }), { server: true, lazy: true })

  const fetchChildrenOfCollection = async (collectionId: string | string[]) => {
    const parentIds = Array.isArray(collectionId) ? collectionId : [collectionId]
    const existingIds = new Set(Object.keys(collections.value ?? {}))

    const fetchAndUpdateCollections = async (skip: number) => {
      const result = await fetchCollections({
        skip,
        take: LIST_QUERY_LIMIT,
        filter: { parentId: { in: parentIds } },
      })

      const newCollections = result.data.collections.items.filter(collection => !existingIds.has(collection.id))

      collections.value = {
        ...collections.value,
        ...Object.fromEntries(newCollections.map(item => [item.id, item])),
      }

      newCollections.forEach(collection => existingIds.add(collection.id))

      return result
    }

    const initialCollections = await fetchAndUpdateCollections(0)

    const totalItems = initialCollections.data.collections.totalItems

    const totalBatches = Math.ceil(totalItems / LIST_QUERY_LIMIT)

    // Fetch and update in further batches
    for (let batch = 1; batch < totalBatches; batch++) {
      const skip = batch * LIST_QUERY_LIMIT
      await fetchAndUpdateCollections(skip)
    }
  }

  return {
    provide: {
      collections: {
        collections: readonly(collections),
        rootCollections,
        collectionTree,
        fetchChildrenOfCollection,
      },
    },
  }
})
