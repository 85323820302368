import type { CreateAddressInput } from '#graphql-operations'

export function shippingFormDataIsValid(data: Partial<CreateAddressInput>): data is CreateAddressInput {
  const shippingFormData = (data instanceof FormData ? Object.fromEntries<any>(data.entries()) : data) as CreateAddressInput
  return Boolean(shippingFormData.city && shippingFormData.countryCode && shippingFormData.streetLine1 && shippingFormData.postalCode)
}

export const replaceEmptyString = (input: string | undefined | null) => (!input || input.trim().length === 0) ? '-' : input

export function isEmptyShippingAddress(address: CreateAddressInput) {
  const shippingFields = ['streetLine1', 'city', 'postalCode'] as const
  return shippingFields.every(field => !address[field])
}
