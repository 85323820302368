import type { Ref } from 'vue'

// @ts-expect-error - runtime module
import { logger } from '#build/gtm-logger.mjs'
import { nextTick, useState } from '#imports'

declare global {
  interface Window {
    /**
     * Guard to prevent multiple GTM initialization
     */
    _gtm_init?: number

    /**
     * Inject GTM Container into DOM
     * @param containerId
     */
    _gtm_inject?: (containerId: string) => void

    /**
     * Holds GTM Container IDs which are already initialized
     */
    _gtm_ids?: Record<string, number>
  }
}

interface GTMState {
  containers: Record<string, boolean>
  events: Record<string, any>[]
}

function startPageTracking(gtm: any) {
  const router = useRouter()
  router.afterEach(to => nextTick().then(() => {
    gtm.push(to.meta.gtm || {
      event: 'route_change',
      routeName: to.name ?? '',
      pageType: 'PageView',
      pagePath: to.fullPath,
      pageTitle: to.meta.title || (document?.title || ''),
    })
  }))
}

function gtmClient(gtmState: Ref<GTMState>) {
  const { gtm } = useRuntimeConfig().public

  return {
    init: (containerId = gtm.containerId) => {
      if (gtmState.value.containers[containerId] || !window._gtm_inject) {
        logger.debug('[client] already initialized or no _gtm_inject', containerId)
        return
      }

      window._gtm_inject(containerId) // load GTM with containerId
      gtmState.value.containers[containerId] = true
      logger.debug('[client] initialized', containerId)
    },

    push: (payload: Record<string, any>) => {
      (window as any)[gtm.dataLayer.name] ??= []
      window[gtm.dataLayer.name as keyof Window].push(payload)
      logger.debug('[client] pushed', payload)
    },
  }
}

function gtmServer(gtmState: Ref<GTMState>) {
  const { gtm } = useRuntimeConfig().public

  return {
    init: (containerId = gtm.containerId) => {
      if (gtmState.value.containers[containerId]) {
        logger.debug('[server] already initialized', containerId)
        return
      }

      gtmState.value.containers[containerId] = true
      logger.debug('[server] initialized', containerId)
    },

    push: (payload: Record<string, any>) => {
      gtmState.value.events.push(payload)
      logger.debug('[server] pushed', payload)
    },
  }
}

export default defineNuxtPlugin((_nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public
  const gtmState = useState<GTMState>('gtmState', () => ({ containers: {}, events: [] }))

  // if (runtimeConfig.gtm.containerId) {
  //   gtmState.value.containers[runtimeConfig.gtm.containerId] = runtimeConfig.gtm.autoInit
  // }

  const gtm = import.meta.server ? gtmServer(gtmState) : gtmClient(gtmState)

  if (runtimeConfig.gtm.autoInit && runtimeConfig.gtm.containerId)
    gtm.init(runtimeConfig.gtm.containerId)

  if (import.meta.client && runtimeConfig.gtm.pageTracking)
    startPageTracking(gtm)

  return {
    provide: { gtm },
  }
})
