import type { CreateAddressInput } from '#graphql-operations'

interface CustomOrderStates {}
export type OrderState = 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export const useActiveOrder = () => useNuxtApp().$activeOrder

const addressFields: Array<keyof CreateAddressInput> = ['city', 'company', 'countryCode', 'fullName', 'phoneNumber', 'province', 'streetLine1', 'streetLine2']
const addressFieldsLength = addressFields.length
export function assignBlankAddressFields(input: Partial<CreateAddressInput>) {
  for (let i = 0; i < addressFieldsLength; i++) {
    const field = addressFields[i]
    if (!(field in input) || input[field] == null)
      input[field] = ''
  }

  return input as CreateAddressInput
}
