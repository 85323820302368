import { randomUUID } from 'uncrypto'
import type { HookResult } from '@nuxt/schema'
import type { ComputedRef, Ref } from 'vue'
import type { DeepReadonly } from '@vue/reactivity'
import type MessageList from '~/components/messenger/messages/message-list.vue'
import { defineNuxtPlugin, useNuxtApp, useState } from '#imports'
import type { DetectIntentMutation, GcxDetectIntentRequest, GcxQueryInput, GcxQueryParameters } from '#graphql-operations'

export interface RichContentTitle {
  type: 'info'
  title: string
  subtitle: string
  image: {
    src: {
      rawUrl: string
    }
  }
  actionLink: string
}

export interface RichContentDescription {
  type: 'description'
  title: string
  text: string[]
}

export interface RichContentImage {
  type: 'image'
  rawUrl: string
  accessibilityText: string
}

export interface RichContentButton {
  type: 'button'
  mode: 'blocking' | string
  icon: {
    type: string
    color: string
  }
  image: {
    src: {
      rawUrl: string
    }
  }
  text: string
  link: string
  event: {
    name: string
    parameters: { [key: string]: unknown }
  }
}

export interface RichContentList {
  type: 'list'
  title: string
  subtitle: string
  event: {
    name: string
  }
}

export interface RichContentDivider {
  type: 'divider'
}

export interface RichContentAccordion {
  type: 'accordion'
  title: string
  subtitle: string
  image: {
    src: {
      rawUrl: string
    }
  }
  text: string
}

export interface RichContentChips {
  type: 'chips'
  options: {
    mode: 'blocking' | string
    text: string
    image: {
      src: {
        rawUrl: string
      }
    }
    link: string
    event: {
      name: string
      parameters: { [key: string]: unknown }
    }
  }[]
}

export interface TextContent {
  type: 'text'
  text: string
}

export type RichContentMessage = (
  | RichContentTitle
  | RichContentDescription
  | RichContentImage
  | RichContentButton
  | RichContentList
  | RichContentDivider
  | RichContentAccordion
  | RichContentChips
  | TextContent
) & { languageCode: string, isBot: boolean }

export type MessengerMessage =
  | (RichContentChips | TextContent) & { languageCode: string, isBot: boolean }
  | (Exclude<RichContentMessage, RichContentChips | TextContent>)[]

interface MessengerState {
  expand: boolean
  chatTitle: string
  botWriting: boolean
  minimized: boolean
  messages: MessengerMessage[]
  showErrorToast: boolean
  showFileUpload: boolean
  sessionId: string
  languageCode: string
}

declare module '#app' {
  interface RuntimeNuxtHooks {
    'messenger:request-sent': (input: GcxDetectIntentRequest) => HookResult
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const { locale, t } = useNuxtApp().$i18n

  const messenger = useState<MessengerState>(() => ({
    expand: false,
    chatTitle: 'Chat',
    botWriting: false,
    minimized: true,
    messages: [],
    showErrorToast: false,
    showFileUpload: false,
    sessionId: randomUUID(),
    languageCode: locale.value,
  }))

  const messageListRef = useState<InstanceType<typeof MessageList>>()
  const customerToken = ref<string>()
  const clearCustomerToken = () => customerToken.value = undefined

  const maximizeChat = () => {
    messenger.value.expand = true
    messenger.value.minimized = false
  }

  const closeChat = () => {
    messenger.value.minimized = messenger.value.expand = false
  }

  const addMessage = async (message: MessengerMessage) => {
    if (!message)
      return

    if (Array.isArray(message)) {
      message = message.map(content => ({
        ...content,
        languageCode: content.languageCode || messenger.value.languageCode,
      }))
    }
    else {
      message = {
        ...message,
        languageCode: message.languageCode || messenger.value.languageCode,
      }
    }

    messenger.value.messages = [...messenger.value.messages, message]

    await nextTick()

    setTimeout(() => {
      const lastMessageElement = messageListRef.value?.lastElementChild
      lastMessageElement?.scrollIntoView({ behavior: 'smooth' })
    }, 50)
  }

  const detectIntent = async (input: GcxDetectIntentRequest) => {
    if (!input.queryInput.text?.text && !input.queryInput.event?.event)
      throw new Error('Invlid query input, text or event is required')

    messenger.value.botWriting = true
    await nuxtApp.callHook('messenger:request-sent', input)

    const result = (await useGraphqlMutation('detectIntent', { input })).data.detectIntent
    // emit response received

    return result
  }

  const processResponse = async (response: DetectIntentMutation['detectIntent']) => {
    const { sessionId, languageCode, queryResult } = response

    messenger.value.botWriting = false
    messenger.value.sessionId = sessionId
    messenger.value.languageCode = languageCode

    if (!queryResult?.responseMessages?.length)
      return

    for (const responseMessage of queryResult.responseMessages) {
      // process response message
      if (responseMessage?.text?.text?.length) {
        for (const text of responseMessage.text.text) {
          await addMessage({
            type: 'text',
            isBot: true,
            languageCode,
            text,
          })
        }
      }

      // process rich content
      const richContents = responseMessage?.payload?.richContent as RichContentMessage[][] | [] | undefined

      if (richContents?.length) {
        for (const contents of richContents) {
          const customCardContents = [] as (Exclude<RichContentMessage, RichContentChips | TextContent> & { languageCode: string, isBot: boolean })[]
          const chipsContents = [] as (RichContentChips & { languageCode: string, isBot: boolean })[]

          for (const contentItem of contents.filter(contentItem => !!contentItem?.type)) {
            const mappedContent = mapRichContentMessage({
              ...contentItem,
              isBot: true,
              languageCode,
            })

            if (mappedContent?.type) {
              if (mappedContent.type !== 'chips')
                customCardContents.push(mappedContent as Exclude<RichContentMessage, RichContentChips | TextContent> & { languageCode: string, isBot: boolean })
              else
                chipsContents.push(mappedContent as RichContentChips & { languageCode: string, isBot: boolean })
            }
          }

          if (customCardContents.length)
            await addMessage(customCardContents)

          for (const chips of chipsContents)
            await addMessage(chips)
        }
      }

      // handle file upload
      if (responseMessage?.payload?.action === 'file_upload') {
        messenger.value.showFileUpload = true
        customerToken.value = responseMessage?.payload?.customerToken
      }
    }
  }

  const sendMessage = async (queryInput: GcxQueryInput, queryParams: GcxQueryParameters = {}) => {
    if (!queryInput.text?.text && !queryInput.event?.event)
      return

    if (queryInput.text?.text.length) {
      await addMessage({
        type: 'text',
        isBot: false,
        languageCode: queryInput.languageCode || messenger.value.languageCode,
        text: queryInput.text.text,
      })
    }

    const input: GcxDetectIntentRequest = {
      queryInput: {
        ...queryInput,
        languageCode: pickSupportedLanguageCode(queryInput.languageCode ?? messenger.value.languageCode),
      },
      queryParams: {
        ...queryParams,
        channel: 'Web',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      session: messenger.value.sessionId,
    }

    const result = await detectIntent(input)
    // handle error
    await processResponse(result)
  }

  const renderCustomCard = async (payload: MessengerMessage) => {
    await addMessage(payload)
  }

  const renderCustomText = async (text: string, isBot = true) => {
    await addMessage({ type: 'text', isBot, languageCode: messenger.value.languageCode, text })
  }

  const validateInput = (value = '') => {
    return value.length > 0 && value.length < 256
  }

  const showBotWriting = computed(() => messenger.value.botWriting)

  nuxtApp.provide('supportAgent', {
    messenger,
    customerToken: readonly(customerToken),
    messageListRef,
    showBotWriting,
    maximizeChat,
    closeChat,
    addMessage,
    sendMessage,
    validateInput,
    renderCustomCard,
    renderCustomText,
    clearCustomerToken,
  })

  // hooks
  nuxtApp.hook('messenger:request-sent', async (input: GcxDetectIntentRequest) => {
    const findSelectOrderLineGroupIndex = (messageGroups: MessengerMessage[]) => messageGroups.findIndex(group =>
      Array.isArray(group) && group.some(message =>
        'event' in message && message.isBot && message.event?.name === 'SELECT_ORDER_LINE',
      ),
    )

    if (input?.queryInput?.event?.event === 'SELECT_ORDER_LINE' && input?.queryParams?.parameters?.order_line_id) {
      const buttonGroupIndex = findSelectOrderLineGroupIndex(messenger.value.messages)

      if (buttonGroupIndex > -1) {
        const buttonGroup = messenger.value.messages[buttonGroupIndex] as RichContentButton[]
        const button = buttonGroup.find(message => input?.queryParams?.parameters?.order_line_id === message?.event?.parameters?.order_line_id)
        messenger.value.messages.splice(buttonGroupIndex, 1)
        if (button?.text) {
          await addMessage({
            type: 'text',
            isBot: false,
            languageCode: input.queryInput.languageCode || messenger.value.languageCode,
            text: button.text,
          })
        }
      }
    }
  })

  if (!messenger.value.messages?.length) {
    void renderCustomCard({
      type: 'chips',
      isBot: true,
      languageCode: messenger.value.languageCode,
      options: [
        { text: `${t('support_agent.chip.order_status')} 📦` },
        { text: `${t('support_agent.chip.account_settings')} ⚙️` },
        { text: `${t('support_agent.chip.cancel_order')} ❌` },
        { text: `${t('support_agent.chip.return_order')} 🔄️` },
        { text: `${t('support_agent.chip.dispute_order')} ⚠️` },
      ] as RichContentChips['options'],
    })
  }
})

function pickSupportedLanguageCode(currentLanguageCode: string) {
  const supportedLanguages = ['en', 'cs']
  if (supportedLanguages.includes(currentLanguageCode))
    return currentLanguageCode
  return 'en'
}

function mapRichContentMessage(content: RichContentMessage & { isBot: boolean, languageCode: string }) {
  const { type, isBot, languageCode } = content

  const base = { type: 'NULL', isBot, languageCode }

  switch (type) {
    case 'info': {
      return {
        ...base,
        type,
        title: content.title,
        subtitle: content.subtitle,
        image: {
          src: {
            rawUrl: content.image?.src?.rawUrl,
          },
        },
        actionLink: content.actionLink,
      }
    }

    case 'description': {
      return {
        ...base,
        type,
        title: content.title,
        text: content.text ? Array.isArray(content.text) ? content.text : [content.text] : [],
      }
    }

    case 'button': {
      return {
        ...base,
        type,
        mode: content.mode,
        icon: content.icon,
        image: content.image,
        text: content.text,
        link: content.link,
        event: {
          name: content.event?.name,
          parameters: content.event?.parameters,
        },
      }
    }

    case 'image': {
      return {
        ...base,
        type,
        rawUrl: content.rawUrl,
        accessibilityText: content.accessibilityText,
      }
    }

    case 'list': {
      return {
        ...base,
        type,
        title: content.title,
        subtitle: content.subtitle,
        event: {
          name: content.event?.name,
        },
      }
    }

    case 'divider': {
      return {
        ...base,
        type,
      }
    }

    case 'accordion': {
      return {
        ...base,
        type,
        title: content.title,
        subtitle: content.subtitle,
        image: {
          src: {
            rawUrl: content.image?.src?.rawUrl,
          },
        },
        text: content.text,
      }
    }

    case 'chips': {
      return {
        ...base,
        type,
        options: content.options
          ? content.options.map((option: any) => ({
            mode: option.mode,
            text: option.text,
            link: option.link,
            image: {
              src: {
                rawUrl: option.image?.src?.rawUrl,
              },
            },
            event: {
              name: option.event?.name,
              parameters: option.event?.parameters,
            },
          }))
          : [],
      }
    }

    default: {
      console.warn(`Unknown rich content type: "${type}"`)
      return base
    }
  }
}

interface SupportAgentPlugin {
  messenger: Ref<MessengerState>
  customerToken: DeepReadonly<Ref<string>>
  messageListRef: Ref<InstanceType<typeof MessageList>>
  showBotWriting: ComputedRef<boolean>
  maximizeChat: () => void
  closeChat: () => void
  addMessage: (message: MessengerMessage) => Promise<void>
  sendMessage: (queryInput: GcxQueryInput, queryParams?: GcxQueryParameters) => Promise<void>
  validateInput: (value: string) => boolean
  renderCustomCard: (payload: MessengerMessage) => Promise<void>
  renderCustomText: (text: string, isBot?: boolean) => Promise<void>
  clearCustomerToken: () => void
}

declare module '#app' {
  interface NuxtApp {
    $supportAgent: SupportAgentPlugin
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $supportAgent: SupportAgentPlugin
  }
}
