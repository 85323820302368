export const translationImports = {
  'en': import('@vee-validate/i18n/dist/locale/en.json'),
  'en-us': import('@vee-validate/i18n/dist/locale/en.json'),
  'cs': import('@vee-validate/i18n/dist/locale/cs.json'),
  'sk': import('@vee-validate/i18n/dist/locale/sk.json'),
  'pl': import('@vee-validate/i18n/dist/locale/pl.json'),
  'hu': import('@vee-validate/i18n/dist/locale/hu.json'),
  'ro': import('@vee-validate/i18n/dist/locale/ro.json'),
}

// NOTE: Loading of correct locale is currently done by the page reload on locale change

export default defineNuxtPlugin({
  name: 'vee-validate',
  parallel: true,
  async setup(nuxtApp) {
    const { configure, defineRule } = await import('vee-validate')
    const { localize, setLocale } = await import('@vee-validate/i18n')

    const locale = unref((nuxtApp.$i18n as any).locale)
    const translations = await translationImports[locale as keyof typeof translationImports].then(module => module.default)
    localize({ [locale]: translations })

    const { all } = await import('@vee-validate/rules')
    Object.entries(all).forEach(([rule, validator]) => defineRule(rule, validator))

    setLocale(locale)

    configure({
      generateMessage: localize({
        [locale]: translations,
      }),
    })
  },
})
