<script setup lang="ts">
const props = defineProps({
  error: Object,
})

const { t } = useI18n()
const localePath = useLocalePath()

const message = computed(() => String(props.error?.message || ''))
const is404 = computed(() => props.error?.statusCode === 404 || message.value?.includes('404'))
const isDev = process.dev

const handleError = () => clearError({ redirect: localePath('/') })
</script>

<template>
  <NuxtLayout>
    <div class="mx-auto max-w-3xl px-4 pb-16 pt-12 lg:max-w-[88rem] md:max-w-4xl lg:px-8 sm:px-6 sm:pb-24 sm:pt-16">
      <div class="h-screen flex flex-col items-center gap4 text-center">
        <div n="3xl">
          {{ is404 ? t('error.404') : t('error.500') }}
        </div>

        <div n="xl" class="op50">
          {{ t('error.subtext') }}
        </div>

        <div v-if="isDev" class="rounded-3xl bg-slate-8 px8 py4">
          <div class="text-left text-context" n="blue3">
            {
          </div>
          <div v-for="(value, key, index) in error" :key="index" class="pl4 text-left font-mono">
            <span class="text-context" n="white">
              "{{ key }}"
            </span>

            <span class="text-context" n="blue3">
              :
            </span>

            <span class="text-context" n="green3">
              "{{ value }}"
            </span>

            <span v-if="index < Object.keys(error).length - 1" class="text-context" n="blue3">
              ,
            </span>
          </div>
          <div class="text-left text-context" n="blue3">
            }
          </div>
        </div>

        <NButton
          id="go-back"
          n="red4"
          class="rounded-full"
          @click="handleError"
        >
          Go Back
        </NButton>
      </div>
    </div>
  </NuxtLayout>
</template>
