import revive_payload_client_H42qZEDty9 from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AaUuciqEWM from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Yqqy3tVHRy from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_XzHGyb7FaA from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lNxVv3jTte from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_e7xydASs6j from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lVrlOBjMQE from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_xeOOPz7fiz from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xkGf2LM3JS from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_K2bMWldKEB from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_nuxt@3.13.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_Mp9QcMIJTS from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_rollup@4.21.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import provideState_8WVjq1iFvj from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_graphql-tag@2.12.6_graphql@16.9.0_nuxt@3.13.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_uNYPflKUpC from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.21.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_pPgjKLMFme from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_j09xf8zqO1 from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_rollup@4.21.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_H42qZEDty9,
  unhead_AaUuciqEWM,
  router_Yqqy3tVHRy,
  payload_client_XzHGyb7FaA,
  navigation_repaint_client_lNxVv3jTte,
  check_outdated_build_client_e7xydASs6j,
  chunk_reload_client_lVrlOBjMQE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xeOOPz7fiz,
  plugin_client_xkGf2LM3JS,
  documentDriven_K2bMWldKEB,
  nuxt_plugin_Mp9QcMIJTS,
  provideState_8WVjq1iFvj,
  plugin_uNYPflKUpC,
  i18n_pPgjKLMFme,
  plugin_j09xf8zqO1,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]