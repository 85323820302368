// get the gql object type from a gql type
export type GqlType<T, TName extends string> = T extends { __typename: TName } ? T : never

// get records with key gql errors
export type GqlError<T> = T extends { errorCode: string } ? T : never

interface GqlResult {
  [key: string]: unknown
  __typename?: string
}

export function isGqlType<T extends GqlResult | null | undefined, TName extends string>(obj: T, typename: TName): obj is GqlType<T, TName> {
  return obj?.__typename === typename
}

export function isGraphQLError<T extends GqlResult | null | undefined>(obj: T): obj is GqlError<T> {
  return obj?.errorCode != null
}

export const isGraphqlError = isGraphQLError
export const isGraphqlType = isGqlType
