import type { CollectionListItemFragment } from '#graphql-operations'

export type CollectionListItemFragmentWithChildren = CollectionListItemFragment & { children: { [id: string]: CollectionListItemFragmentWithChildren } }

export function buildTree(collections?: CollectionListItemFragment[] | null) {
  if (collections == null)
    return {}

  const lookup: { [key: string]: CollectionListItemFragmentWithChildren } = {}
  const rootNodes: { [id: string]: CollectionListItemFragmentWithChildren } = {}

  for (const collection of collections)
    lookup[collection.id] = { ...collection, children: {} }

  for (const collection of collections) {
    if (collection.parent && collection.parent.name === '__root_collection__') {
      rootNodes[collection.id] = lookup[collection.id]
    }
    else if (collection.parent && collection.parent.id in lookup) {
      if (collection.id !== collection.parent.id)
        lookup[collection.parent.id].children[collection.id] = lookup[collection.id]
      else
        console.error(`Circular reference detected in collection with id ${collection.id}`)
    }
  }

  return rootNodes
}

export function useCollections() {
  return useNuxtApp().$collections
}
